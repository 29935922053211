<template>
    <div class="card-tail" v-loading="$waiting.is('loading')">
        <div style="padding: 10px;">
            <div style="display: flex; justify-content: space-between; width: 100%;" :class="{ inactive: !isActive }">
                <el-col :span="15">
                    <h1 v-if="invoiceCustomerDetails.isDenied" style="font-weight: 500; font-size:16px; color:#e31616;">OBS! DETTA FAKTURABESLUT ÄR NEKAT</h1>
                    <el-card class="box-card" style="margin-top: 10px; margin-right: 20px; margin-bottom: 30px; width: 90%;">
                        <div slot="header" style="display: flex; justify-content: space-between; padding:0px;">
                            <span style="margin-top:5px;">Fakturaköpsklient</span>
                            <router-link :to="'/clientApproved/' + this.$route.params.clientId + '/credit'">
                                <el-button type="primary">
                                    Visa fullständigt kreditkort
                                </el-button>
                            </router-link>
                        </div>
                        <el-row style="margin-bottom:5px;">
                            <el-col :span="6" style="font-weight: 500; font-size:13px;">
                                {{ invoiceCustomerDetails.clientName }}
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:5px;">
                            <el-col :span="10" style="font-weight: 500; font-size:13px;">Utnyttjad kredit: {{ invoiceCustomerDetails.usedCredit | swedishNumberFormat }} kr</el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="5" style="font-weight: 500; font-size:13px;">Beviljad kredit: {{ invoiceCustomerDetails.approvedCredit | swedishNumberFormat }} kr</el-col>
                            <el-col v-if="invoiceCustomerDetails.isCreditLimitExceeded" :span="17" style="font-weight: 500; font-size:13px; color:#e31616;">
                                OBS! Kreditgräns för låg. Var god ta ett nytt kreditbeslut på klient. Belopp som överstiger:
                                {{ invoiceCustomerDetails.exceededCreditLimitValue | swedishNumberFormat }} kr</el-col
                            >
                        </el-row>
                    </el-card>

                    <el-card class="box-card" style="margin-top: 10px; margin-right: 20px; margin-bottom: 30px; width: 90%;">
                        <div slot="header">
                            <span style="margin-top:5px;">Kundinformation från Syna</span>
                            <el-button :loading="loadingFetch" type="primary" style="float: right; padding: 6px 6px" @click="updateCustomerInfo()">Ny upplysning via Syna</el-button>
                            <div v-if="invoiceCustomerDetails.creditReportFetchingTime != null" style="float: right; margin-right:10px; font-size:13px">
                                Senast hämtat: {{ formatDate(invoiceCustomerDetails.creditReportFetchingTime) }}
                            </div>
                            <div class="tooltip-container">
                                <div div v-if="invoiceCustomerDetails.hasBeenMoreThan5Days" class="tooltip-trigger">
                                    <i class="fa-regular fa-circle-exclamation" style="color: #f90606;" />
                                </div>
                                <div v-if="invoiceCustomerDetails.hasBeenMoreThan5Days" class="tooltip-text">Dags att hämta ny information!</div>
                            </div>
                        </div>
                        <!-- <el-row>
                            <el-col :span="11" style="margin-bottom: 15px;">
                                <el-button type="primary">Öppna fullständig kreditkontroll</el-button>
                            </el-col>
                        </el-row> -->
                        <el-row>
                            <el-col :span="12" style="font-weight: 700; font-size:14px; margin-bottom: 20px;">Angivet av kunden</el-col>
                            <el-col :span="12" style="font-weight: 700; font-size:14px; margin-bottom: 20px;">Uppgifter från Syna</el-col>
                        </el-row>
                        <el-row style="margin-bottom:5px;">
                            <el-col :span="12" style="font-weight: 700; font-size:13px;">Kunduppgifter</el-col>
                            <el-col :span="12" style="font-weight: 700; font-size:13px;">Kunduppgifter</el-col>
                        </el-row>
                        <el-row v-for="(field, index) in fields" :key="index" style="margin-bottom:5px;" :class="{ inactive: !isActive }">
                            <el-col :span="10" style="font-weight: 500; font-size:13px;">
                                <template v-if="!field.editing">
                                    {{ field.value }}
                                </template>
                                <template v-else>
                                    <el-input v-model="field.editedValue" @blur="saveChanges(field)" />
                                </template>
                            </el-col>
                            <el-col :span="2" style="font-weight: 500; font-size: 13px;">
                                <i class="fa-sharp fa-solid fa-pen" style="cursor: pointer; margin-left: 5px;" @click="toggleEditField(field)" />
                            </el-col>

                            <el-col :span="8" style="font-weight: 500; font-size:13px;">
                                <template>
                                    {{ getAdditionalValueForInvoiceDetails(index) }}
                                </template>
                            </el-col>
                        </el-row>

                        <div style="margin-top:15px;" />

                        <div v-if="invoiceCustomerDetails.creditReportFetchingTime != null">
                            <!-- IsCreditRatingAtLeastThree -->
                            <!-- <AproveSwitch /> -->
                            <el-row style="border-top: 1px solid #ccc;">
                                <el-col :span="20" style="font-weight: 700; font-size:13px; margin-top:20px;">Minst kreditklass 3</el-col>
                                <el-col :span="2" style="margin-top:20px;">
                                    <div v-if="invoiceCustomerDetails.isCreditRatingAtLeastThree && isRenderedData">
                                        <i v-if="!switchStates.switchStatesIsCreditRatingAtLeastThree" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesIsCreditRatingAtLeastThree" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                    </div>
                                    <div v-else-if="!invoiceCustomerDetails.isCreditRatingAtLeastThree && isRenderedData">
                                        <i v-if="!switchStates.switchStatesIsCreditRatingAtLeastThree" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesIsCreditRatingAtLeastThree" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                    </div>
                                </el-col>
                                <el-col :span="2" v-if="isRenderedData" style="margin-top:20px;">
                                    <el-switch
                                        v-model="switchStates.switchStatesIsCreditRatingAtLeastThree"
                                        :disabled="switchStates.switchStatesIsCreditRatingAtLeastThree"
                                        :class="{ 'switch-on': switchStates.switchStatesIsCreditRatingAtLeastThree, 'switch-off': !switchStates.switchStatesIsCreditRatingAtLeastThree }"
                                        active-color="#13ce66"
                                        inactive-color="#919191"
                                        @change="showModalAdd('switchStatesIsCreditRatingAtLeastThree', $event)"
                                    />
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :offset="1" class="custom-font-style">Kreditklass: {{ invoiceCustomerDetails.showCreditRating }}</el-col>
                            </el-row>
                            <el-row v-if="!removeComments">
                                <el-col :offset="1">
                                    <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                        <div
                                            style="font-size:13px; font-weight:600;"
                                            v-if="decisionType == selectedDecisionType('switchStatesIsCreditRatingAtLeastThree') && switchStates.switchStatesIsCreditRatingAtLeastThree"
                                        >
                                            Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <!-- IsCreditLimitZero -->
                            <el-row>
                                <el-col :span="20" style="font-weight: 700; font-size:13px;">Kreditgräns (om 0 - ej godkänd)</el-col>
                                <el-col :span="2">
                                    <div v-if="!invoiceCustomerDetails.isCreditLimitZero && isRenderedData">
                                        <i v-if="!switchStates.switchStatesIsCreditLimitZero" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesIsCreditLimitZero" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                    </div>
                                    <div v-else-if="invoiceCustomerDetails.isCreditLimitZero && isRenderedData">
                                        <i v-if="!switchStates.switchStatesIsCreditLimitZero" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesIsCreditLimitZero" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                    </div>
                                </el-col>
                                <el-col :span="2" v-if="isRenderedData">
                                    <el-switch
                                        v-model="switchStates.switchStatesIsCreditLimitZero"
                                        :disabled="switchStates.switchStatesIsCreditLimitZero"
                                        :class="{ 'switch-on': switchStates.switchStatesIsCreditLimitZero, 'switch-off': !switchStates.switchStatesIsCreditLimitZero }"
                                        active-color="#13ce66"
                                        inactive-color="#919191"
                                        @change="showModalAdd('switchStatesIsCreditLimitZero', $event)"
                                    />
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :offset="1" class="custom-font-style">Kreditgräns: {{ invoiceCustomerDetails.showCreditLimit | swedishNumberFormat }} kr</el-col>
                            </el-row>
                            <el-row v-if="!removeComments">
                                <el-col :offset="1">
                                    <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                        <div
                                            style="font-size:13px; font-weight:600;"
                                            v-if="decisionType == selectedDecisionType('switchStatesIsCreditLimitZero') && switchStates.switchStatesIsCreditLimitZero"
                                        >
                                            Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <!-- HasActiveACaseAtKFM -->
                            <el-row>
                                <el-col :span="20" style="font-weight: 700; font-size:13px;">Ej skatteskuldsaldo hos KFM (Aktivt A-mål)</el-col>
                                <el-col :span="2">
                                    <div v-if="!invoiceCustomerDetails.hasActiveACaseAtKfm && isRenderedData">
                                        <i v-if="!switchStates.switchStatesHasActiveACaseAtKfm" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesHasActiveACaseAtKfm" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                    </div>
                                    <div v-else-if="invoiceCustomerDetails.hasActiveACaseAtKfm && isRenderedData">
                                        <i v-if="!switchStates.switchStatesHasActiveACaseAtKfm" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesHasActiveACaseAtKfm" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                    </div>
                                </el-col>
                                <el-col :span="2" v-if="isRenderedData">
                                    <el-switch
                                        v-model="switchStates.switchStatesHasActiveACaseAtKfm"
                                        :disabled="switchStates.switchStatesHasActiveACaseAtKfm"
                                        :class="{ 'switch-on': switchStates.switchStatesHasActiveACaseAtKfm, 'switch-off': !switchStates.switchStatesHasActiveACaseAtKfm }"
                                        active-color="#13ce66"
                                        inactive-color="#919191"
                                        @change="showModalAdd('switchStatesHasActiveACaseAtKfm', $event)"
                                    />
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :offset="1" class="custom-font-style">Skuldsaldo: {{ invoiceCustomerDetails.showKfmDebtBalance | swedishNumberFormat }} kr</el-col>
                            </el-row>
                            <el-row v-if="!removeComments">
                                <el-col :offset="1">
                                    <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                        <div
                                            style="font-size:13px; font-weight:600;"
                                            v-if="decisionType == selectedDecisionType('switchStatesHasActiveACaseAtKfm') && switchStates.switchStatesHasActiveACaseAtKfm"
                                        >
                                            Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <!-- HasAuditNote -->
                            <el-row>
                                <el-col :span="20" style="font-weight: 700; font-size:13px;">Ej revisorsanmärkningar</el-col>
                                <el-col :span="2">
                                    <div v-if="!invoiceCustomerDetails.hasAuditNote && isRenderedData">
                                        <i v-if="!switchStates.switchStatesHasAuditNote" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesHasAuditNote" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                    </div>
                                    <div v-else-if="invoiceCustomerDetails.hasAuditNote && isRenderedData">
                                        <i v-if="!switchStates.switchStatesHasAuditNote" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesHasAuditNote" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                    </div>
                                </el-col>
                                <el-col :span="2" v-if="isRenderedData">
                                    <el-switch
                                        v-model="switchStates.switchStatesHasAuditNote"
                                        :disabled="switchStates.switchStatesHasAuditNote"
                                        :class="{ 'switch-on': switchStates.switchStatesHasAuditNote, 'switch-off': !switchStates.switchStatesHasAuditNote }"
                                        active-color="#13ce66"
                                        inactive-color="#919191"
                                        @change="showModalAdd('switchStatesHasAuditNote', $event)"
                                    />
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :offset="1" class="custom-font-style">Antal revisorsanmärkningar: {{ invoiceCustomerDetails.showNumberOfAccountantComments }} st</el-col>
                            </el-row>
                            <el-row v-if="!removeComments">
                                <el-col :offset="1">
                                    <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                        <div style="font-size:13px; font-weight:600;" v-if="decisionType == selectedDecisionType('switchStatesHasAuditNote') && switchStates.switchStatesHasAuditNote">
                                            Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <!-- IsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance -->
                            <el-row>
                                <el-col :span="20" style="font-weight: 700; font-size:13px;">Om fakturabeloppet är större än 50% av aktuella/aktiva betalningsanmärkningar och skuldsaldo (e) </el-col>
                                <el-col :span="2">
                                    <div v-if="!invoiceCustomerDetails.isInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance && isRenderedData">
                                        <i
                                            v-if="!switchStates.switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance"
                                            class="fa-solid fa-check fa-lg"
                                            style="color: #14bf08; cursor: pointer;"
                                        />
                                        <i
                                            v-if="switchStates.switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance"
                                            class="fa-solid fa-check fa-lg yellow-check-icon"
                                            style="color: #edf104; cursor:pointer;"
                                        />
                                    </div>
                                    <div v-else-if="invoiceCustomerDetails.isInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance && isRenderedData">
                                        <i
                                            v-if="!switchStates.switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance"
                                            class="fa-solid fa-ban fa-lg"
                                            style="color: #e31616; cursor: pointer;"
                                        />
                                        <i
                                            v-if="switchStates.switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance"
                                            class="fa-solid fa-check fa-lg yellow-check-icon"
                                            style="color: #edf104;  cursor:pointer;"
                                        />
                                    </div>
                                </el-col>
                                <el-col :span="2" v-if="isRenderedData">
                                    <el-switch
                                        v-model="switchStates.switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance"
                                        :disabled="switchStates.switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance"
                                        :class="{
                                            'switch-on': switchStates.switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance,
                                            'switch-off': !switchStates.switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance,
                                        }"
                                        active-color="#13ce66"
                                        inactive-color="#919191"
                                        @change="showModalAdd('switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance', $event)"
                                    />
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :offset="1" class="custom-font-style">50% av skuldsaldo: {{ invoiceCustomerDetails.show50PercentOfDebtBalance | swedishNumberFormat }} kr</el-col>
                            </el-row>
                            <el-row v-if="!removeComments">
                                <el-col :offset="1">
                                    <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                        <div
                                            style="font-size:13px; font-weight:600;"
                                            v-if="
                                                decisionType == selectedDecisionType('switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance') &&
                                                    switchStates.switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance
                                            "
                                        >
                                            Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <!-- HasReqMoreThan8TimesPast3Months -->
                            <el-row>
                                <el-col :span="20" style="font-weight: 700; font-size:13px;">Om fler förfrågningar än 8 de senaste 3 månaderna</el-col>
                                <el-col :span="2">
                                    <div v-if="!invoiceCustomerDetails.hasReqMoreThan8TimesPast3Months && isRenderedData">
                                        <i v-if="!switchStates.switchStatesHasReqMoreThan8TimesPast3Months" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesHasReqMoreThan8TimesPast3Months" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                    </div>
                                    <div v-else-if="invoiceCustomerDetails.hasReqMoreThan8TimesPast3Months && isRenderedData">
                                        <i v-if="!switchStates.switchStatesHasReqMoreThan8TimesPast3Months" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesHasReqMoreThan8TimesPast3Months" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                    </div>
                                </el-col>
                                <el-col :span="2" v-if="isRenderedData">
                                    <el-switch
                                        v-model="switchStates.switchStatesHasReqMoreThan8TimesPast3Months"
                                        :disabled="switchStates.switchStatesHasReqMoreThan8TimesPast3Months"
                                        :class="{ 'switch-on': switchStates.switchStatesHasReqMoreThan8TimesPast3Months, 'switch-off': !switchStates.switchStatesHasReqMoreThan8TimesPast3Months }"
                                        active-color="#13ce66"
                                        inactive-color="#919191"
                                        @change="showModalAdd('switchStatesHasReqMoreThan8TimesPast3Months', $event)"
                                    />
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :offset="1" class="custom-font-style">Antal förfrågningar: {{ invoiceCustomerDetails.showAmountOfRequests }} st</el-col>
                            </el-row>
                            <el-row v-if="!removeComments">
                                <el-col :offset="1">
                                    <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                        <div
                                            style="font-size:13px; font-weight:600;"
                                            v-if="decisionType == selectedDecisionType('switchStatesHasReqMoreThan8TimesPast3Months') && switchStates.switchStatesHasReqMoreThan8TimesPast3Months"
                                        >
                                            Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <!-- HasFSkatt -->
                            <el-row>
                                <el-col :span="20" style="font-weight: 700; font-size:13px;">F-skatt</el-col>
                                <el-col :span="2">
                                    <div v-if="invoiceCustomerDetails.hasFSkatt && isRenderedData">
                                        <i v-if="!switchStates.switchStatesHasFSkatt" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesHasFSkatt" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                    </div>
                                    <div v-else-if="!invoiceCustomerDetails.hasFSkatt && isRenderedData">
                                        <i v-if="!switchStates.switchStatesHasFSkatt" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesHasFSkatt" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                    </div>
                                </el-col>
                                <el-col :span="2" v-if="isRenderedData">
                                    <el-switch
                                        v-model="switchStates.switchStatesHasFSkatt"
                                        :disabled="switchStates.switchStatesHasFSkatt"
                                        :class="{ 'switch-on': switchStates.switchStatesHasFSkatt, 'switch-off': !switchStates.switchStatesHasFSkatt }"
                                        active-color="#13ce66"
                                        inactive-color="#919191"
                                        @change="showModalAdd('switchStatesHasFSkatt', $event)"
                                    />
                                </el-col>
                            </el-row>
                            <el-row v-if="!removeComments">
                                <el-col :offset="1">
                                    <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                        <div style="font-size:13px; font-weight:600;" v-if="decisionType == selectedDecisionType('switchStatesHasFSkatt') && switchStates.switchStatesHasFSkatt">
                                            Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <!-- HasVat -->
                            <el-row>
                                <el-col :span="20" style="font-weight: 700; font-size:13px;">Moms</el-col>
                                <el-col :span="2">
                                    <div v-if="invoiceCustomerDetails.hasVat && isRenderedData">
                                        <i v-if="!switchStates.switchStatesHasVat" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesHasVat" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                    </div>
                                    <div v-else-if="!invoiceCustomerDetails.hasVat && isRenderedData">
                                        <i v-if="!switchStates.switchStatesHasVat" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                        <i v-if="switchStates.switchStatesHasVat" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                    </div>
                                </el-col>
                                <el-col :span="2" v-if="isRenderedData">
                                    <el-switch
                                        v-model="switchStates.switchStatesHasVat"
                                        :disabled="switchStates.switchStatesHasVat"
                                        :class="{ 'switch-on': switchStates.switchStatesHasVat, 'switch-off': !switchStates.switchStatesHasVat }"
                                        active-color="#13ce66"
                                        inactive-color="#919191"
                                        @change="showModalAdd('switchStatesHasVat', $event)"
                                    />
                                </el-col>
                            </el-row>
                            <el-row v-if="!removeComments">
                                <el-col :offset="1">
                                    <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                        <div style="font-size:13px; font-weight:600;" v-if="decisionType == selectedDecisionType('switchStatesHasVat') && switchStates.switchStatesHasVat">
                                            Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <!-- IsAmountMaximum25PercentOfYearlyRevenue -->
                            <el-row>
                                <el-col :span="20" style="font-weight: 700; font-size:13px;">Maxbelopp 25% av årlig omsättning</el-col>
                                <el-col :span="2">
                                    <div v-if="invoiceCustomerDetails.isAmountMaximum25PercentOfYearlyRevenue && isRenderedData">
                                        <i v-if="!switchStates.switchStatesIsAmountMaximum25PercentOfYearlyRevenue" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                        <i
                                            v-if="switchStates.switchStatesIsAmountMaximum25PercentOfYearlyRevenue"
                                            class="fa-solid fa-check fa-lg yellow-check-icon"
                                            style="color: #edf104; cursor:pointer;"
                                        />
                                    </div>
                                    <div v-else-if="!invoiceCustomerDetails.isAmountMaximum25PercentOfYearlyRevenue && isRenderedData">
                                        <i v-if="!switchStates.switchStatesIsAmountMaximum25PercentOfYearlyRevenue" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                        <i
                                            v-if="switchStates.switchStatesIsAmountMaximum25PercentOfYearlyRevenue"
                                            class="fa-solid fa-check fa-lg yellow-check-icon"
                                            style="color: #edf104;  cursor:pointer;"
                                        />
                                    </div>
                                </el-col>
                                <el-col :span="2" v-if="isRenderedData">
                                    <el-switch
                                        v-model="switchStates.switchStatesIsAmountMaximum25PercentOfYearlyRevenue"
                                        :disabled="switchStates.switchStatesIsAmountMaximum25PercentOfYearlyRevenue"
                                        :class="{
                                            'switch-on': switchStates.switchStatesIsAmountMaximum25PercentOfYearlyRevenue,
                                            'switch-off': !switchStates.switchStatesIsAmountMaximum25PercentOfYearlyRevenue,
                                        }"
                                        active-color="#13ce66"
                                        inactive-color="#919191"
                                        @change="showModalAdd('switchStatesIsAmountMaximum25PercentOfYearlyRevenue', $event)"
                                    />
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :offset="1" class="custom-font-style">Årlig omsättning: {{ invoiceCustomerDetails.showAnnualRevenue | swedishNumberFormat }} kr</el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24" :offset="1" class="custom-font-style">25% av årlig oms: {{ invoiceCustomerDetails.show25PercentOfAnnualRevenue | swedishNumberFormat }} kr</el-col>
                            </el-row>
                            <el-row v-if="!removeComments">
                                <el-col :offset="1">
                                    <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                        <div
                                            style="font-size:13px; font-weight:600;"
                                            v-if="
                                                decisionType == selectedDecisionType('switchStatesIsAmountMaximum25PercentOfYearlyRevenue') &&
                                                    switchStates.switchStatesIsAmountMaximum25PercentOfYearlyRevenue
                                            "
                                        >
                                            Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>

                            <ul style="margin-bottom:10px;">
                                <label style="font-weight: 700; font-size: 13px;">Offentliga skulder</label>
                                <li v-for="(debt, index) in invoiceCustomerDetails.publicDebtsJson" :key="index" style="font-size: 13px; font-weight:550;">
                                    <div>{{ debt }}</div>
                                </li>
                            </ul>

                            <ul style="margin-bottom:10px;">
                                <label style="font-weight: 700; font-size: 13px;">Kronofogdskulder</label>
                                <li v-for="(debt, index) in invoiceCustomerDetails.enforcementAgencyDebtsJson" :key="index" style="font-size: 13px; font-weight:550;">
                                    <div>{{ debt }}</div>
                                </li>
                            </ul>

                            <ul style="margin-bottom:10px;">
                                <label style="font-weight: 700; font-size: 13px;">Historiska kronofogdskulder</label>
                                <li v-for="(debt, index) in invoiceCustomerDetails.enforcementAgencyHistoryDebtsJson" :key="index" style="font-size: 13px; font-weight:550;">
                                    <div>{{ debt }}</div>
                                </li>
                            </ul>

                            <ul style="margin-bottom:10px;">
                                <label style="font-weight: 700; font-size: 13px;">Betalningsanmärkningar</label>
                                <li v-for="(note, index) in invoiceCustomerDetails.paymentNotesJson" :key="index" style="font-size: 13px; font-weight:550;">
                                    <div>{{ note }}</div>
                                </li>
                            </ul>
                        </div>
                    </el-card>

                    <el-card class="box-card" style="margin-top: 20px; margin-right: 20px; margin-bottom: 20px; width: 90%;">
                        <div slot="header" class="clearfix">
                            <span>Från tjänsten</span>
                        </div>
                        <!-- IsCustomerBolagerietClient -->
                        <el-row>
                            <el-col :span="11" style="font-weight: 700; font-size:13px;">Är kund klient hos Bolageriet?</el-col>
                        </el-row>
                        <el-row v-if="invoiceCustomerDetails.clientSince !== null">
                            <el-col :span="24" :offset="1" class="custom-font-style">Klient sedan: {{ invoiceCustomerDetails.clientSince }}</el-col>
                        </el-row>
                        <el-row v-if="invoiceCustomerDetails.clientSince === null">
                            <el-col :span="24" :offset="1" class="custom-font-style">Ej Bolageriet klient</el-col>
                        </el-row>
                        <el-row v-if="invoiceCustomerDetails.isCustomerBolagerietClient">
                            <el-col :span="24" :offset="1" class="custom-font-style">Utnyttjad kredit: {{ invoiceCustomerDetails.usedCreditForCustomerWhoIsClient | swedishNumberFormat }} kr</el-col>
                        </el-row>
                        <el-row v-if="invoiceCustomerDetails.isCustomerBolagerietClient">
                            <el-col :span="24" :offset="1" class="custom-font-style">
                                Beviljad kredit: {{ invoiceCustomerDetails.approvedCreditForCustomerWhoIsClient | swedishNumberFormat }} kr</el-col
                            >
                        </el-row>
                        <el-row>
                            <el-col :offset="1">
                                <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                    <div
                                        style="font-size:13px; font-weight:550;"
                                        v-if="decisionType == selectedDecisionType('switchStatesIsCustomerBolagerietClient') && switchStates.switchStatesIsCustomerBolagerietClient"
                                    >
                                        Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <!-- HasCustomerRedFlags -->
                        <el-row>
                            <el-col :span="20" style="font-weight: 700; font-size:13px;">Finns kund med i spärrlistan?</el-col>
                            <el-col :span="2">
                                <div v-if="!invoiceCustomerDetails.hasRedFlags && isRenderedData">
                                    <i v-if="!switchStates.switchStatesHasRedFlags" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesHasRedFlags" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                </div>
                                <div v-else-if="invoiceCustomerDetails.hasRedFlags && isRenderedData">
                                    <i v-if="!switchStates.switchStatesHasRedFlags" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesHasRedFlags" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                </div>
                            </el-col>
                            <el-col :span="2" v-if="isRenderedData">
                                <el-switch
                                    v-model="switchStates.switchStatesHasRedFlags"
                                    :disabled="switchStates.switchStatesHasRedFlags"
                                    :class="{ 'switch-on': switchStates.switchStatesHasRedFlags, 'switch-off': !switchStates.switchStatesHasRedFlags }"
                                    active-color="#13ce66"
                                    inactive-color="#919191"
                                    @change="showModalAdd('switchStatesHasRedFlags', $event)"
                                />
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="24" :offset="1" class="custom-font-style">Antal ärenden: {{ invoiceCustomerDetails.numberOfRedFlagErrands }} st</el-col>
                        </el-row>
                        <el-row>
                            <el-col :offset="1">
                                <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                    <div style="font-size:13px; font-weight:550;" v-if="decisionType == selectedDecisionType('switchStatesHasRedFlags') && switchStates.switchStatesHasRedFlags">
                                        Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <!-- IsCustomerInOurSystem -->
                        <el-row>
                            <el-col :span="20" style="font-weight: 700; font-size:13px;">Finns i tjänsten som kund hos annan klient</el-col>
                            <el-col :span="2">
                                <div v-if="!invoiceCustomerDetails.isCustomerAnotherClientsCustomer && isRenderedData">
                                    <i v-if="!switchStates.switchStatesIsCustomerInOurSystem" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesIsCustomerInOurSystem" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                </div>
                                <div v-else-if="invoiceCustomerDetails.isCustomerAnotherClientsCustomer && isRenderedData">
                                    <i v-if="!switchStates.switchStatesIsCustomerInOurSystem" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesIsCustomerInOurSystem" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                </div>
                            </el-col>
                            <el-col :span="2" v-if="isRenderedData">
                                <el-switch
                                    v-model="switchStates.switchStatesIsCustomerInOurSystem"
                                    :disabled="switchStates.switchStatesIsCustomerInOurSystem"
                                    :class="{ 'switch-on': switchStates.switchStatesIsCustomerInOurSystem, 'switch-off': !switchStates.switchStatesIsCustomerInOurSystem }"
                                    active-color="#13ce66"
                                    inactive-color="#919191"
                                    @change="showModalAdd('switchStatesIsCustomerInOurSystem', $event)"
                                />
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="24" :offset="1" class="custom-font-style">Antal ggr kund dyker upp i system: {{ invoiceCustomerDetails.numberOfTimesCustomerIsInSystem }} ggr</el-col>
                        </el-row>
                        <el-row>
                            <el-col :offset="1">
                                <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                    <div
                                        style="font-size:13px; font-weight:550;"
                                        v-if="decisionType == selectedDecisionType('switchStatesIsCustomerInOurSystem') && switchStates.switchStatesIsCustomerInOurSystem"
                                    >
                                        Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                    </div>
                                </div>
                            </el-col>
                        </el-row>

                        <!-- HasLessThan3Over5Days -->
                        <el-row>
                            <el-col :span="20" style="font-weight: 700; font-size:13px;">Färre än 3 förfallna fakturor som passerat över 5 dagar (senaste 36 mån)</el-col>
                            <el-col :span="2">
                                <div v-if="invoiceCustomerDetails.hasLessThan3Over5Days && isRenderedData">
                                    <i v-if="!switchStates.switchStatesHasLessThan3Over5Days" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesHasLessThan3Over5Days" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                </div>
                                <div v-else-if="!invoiceCustomerDetails.hasLessThan3Over5Days && isRenderedData">
                                    <i v-if="!switchStates.switchStatesHasLessThan3Over5Days" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesHasLessThan3Over5Days" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                </div>
                            </el-col>
                            <el-col :span="2" v-if="isRenderedData">
                                <el-switch
                                    v-model="switchStates.switchStatesHasLessThan3Over5Days"
                                    :disabled="switchStates.switchStatesHasLessThan3Over5Days"
                                    :class="{ 'switch-on': switchStates.switchStatesHasLessThan3Over5Days, 'switch-off': !switchStates.switchStatesHasLessThan3Over5Days }"
                                    active-color="#13ce66"
                                    inactive-color="#919191"
                                    @change="showModalAdd('switchStatesHasLessThan3Over5Days', $event)"
                                />
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" :offset="1" class="custom-font-style">Antal fakturor: {{ invoiceCustomerDetails.showNumberOfInvoices }} st</el-col>
                        </el-row>
                        <el-row>
                            <el-col :offset="1">
                                <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                    <div
                                        style="font-size:13px; font-weight:550;"
                                        v-if="decisionType == selectedDecisionType('switchStatesHasLessThan3Over5Days') && switchStates.switchStatesHasLessThan3Over5Days"
                                    >
                                        Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                    </div>
                                </div>
                            </el-col>
                        </el-row>

                        <!-- IsAveragePayTimeMax15Days -->
                        <el-row>
                            <el-col :span="20" style="font-weight: 700; font-size:13px;">Normal betalningstid - max 15 dagar försenad (senaste 36 mån)</el-col>
                            <el-col :span="2">
                                <div v-if="invoiceCustomerDetails.isAveragePayTimeMax15Days && isRenderedData">
                                    <i v-if="!switchStates.switchStatesIsAveragePayTimeMax15Days" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesIsAveragePayTimeMax15Days" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                </div>
                                <div v-else-if="!invoiceCustomerDetails.isAveragePayTimeMax15Days && isRenderedData">
                                    <i v-if="!switchStates.switchStatesIsAveragePayTimeMax15Days" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesIsAveragePayTimeMax15Days" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                </div>
                            </el-col>
                            <el-col :span="2" v-if="isRenderedData">
                                <el-switch
                                    v-model="switchStates.switchStatesIsAveragePayTimeMax15Days"
                                    :disabled="switchStates.switchStatesIsAveragePayTimeMax15Days"
                                    :class="{ 'switch-on': switchStates.switchStatesIsAveragePayTimeMax15Days, 'switch-off': !switchStates.switchStatesIsAveragePayTimeMax15Days }"
                                    active-color="#13ce66"
                                    inactive-color="#919191"
                                    @change="showModalAdd('switchStatesIsAveragePayTimeMax15Days', $event)"
                                />
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" :offset="1" class="custom-font-style">Genomsnittlig betaltid: {{ invoiceCustomerDetails.showAverageNumberOfDaysToPay }} dagar</el-col>
                        </el-row>
                        <el-row>
                            <el-col :offset="1">
                                <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                    <div
                                        style="font-size:13px; font-weight:550;"
                                        v-if="decisionType == selectedDecisionType('switchStatesIsAveragePayTimeMax15Days') && switchStates.switchStatesIsAveragePayTimeMax15Days"
                                    >
                                        Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                    </div>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="24" style="font-weight: 700; font-size:13px; margin-top:10px;">
                                Totala utomstående summa för kund: {{ invoiceCustomerDetails.totalRemainingBalanceCustomer | swedishNumberFormat }} kr
                            </el-col>
                        </el-row>
                    </el-card>

                    <el-card v-if="invoiceCustomerDetails && invoiceCustomerDetails.invoiceInfo" class="box-card" style="margin-top: 20px; margin-right: 20px; margin-bottom: 20px; width:100%;">
                        <div slot="header" class="clearfix">
                            <span>Faktura</span>
                        </div>
                        <el-row>
                            <p style="font-weight: 500; font-size:13px; color:#e31616; margin-bottom:5px;" v-if="this.invoiceCustomerDetails.isDifferenceMoreThan4Days === true">
                                Det skiljer mer än fyra dagar mellan idag och fakturadatumet, var god ändra.
                            </p>
                        </el-row>
                        <el-row>
                            <el-col :span="5" style="font-weight: 500; font-size:13px; margin-top:5px;">Fakturadatum </el-col>
                            <el-col :span="3" style="font-weight: 500; font-size:13px; margin-top:5px;"> {{ formatDate(invoiceCustomerDetails.invoiceDate) }} </el-col>
                            <el-col :span="7">
                                <el-date-picker v-model="newInvoiceDate" class="mr-3" type="date" placeholder="Välj fakturadatum" />
                            </el-col>
                            <el-col :span="6">
                                <el-button :loading="loadingUpdateInvoiceDate" type="primary" @click="updateInvoiceDates">Spara nytt fakturadatum</el-button>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:5px;">
                            <el-col :span="5" style="font-weight: 500; font-size:13px;">Förfallodatum </el-col>
                            <el-col :span="6" style="font-weight: 500; font-size:13px;"> {{ invoiceCustomerDetails.dueDate }} </el-col>
                        </el-row>

                        <el-row style="margin-bottom:1px;">
                            <el-col :span="5" style="font-weight: 700; font-size:13px;">Betalningsvillkor (max 60 dagar)</el-col>
                            <el-col :span="3" style="font-weight: 500; font-size:13px;">{{ invoiceCustomerDetails.paymentTermsInDays }} dagar</el-col>
                            <el-col :span="1">
                                <div v-if="!invoiceCustomerDetails.isLargerThan60Days && isRenderedData">
                                    <i v-if="!switchStates.switchStatesIsLargerThan60Days" class="fa-solid fa-check fa-lg" style="color: #14bf08; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesIsLargerThan60Days" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104; cursor:pointer;" />
                                </div>
                                <div v-else-if="invoiceCustomerDetails.isLargerThan60Days && isRenderedData">
                                    <i v-if="!switchStates.switchStatesIsLargerThan60Days" class="fa-solid fa-ban fa-lg" style="color: #e31616; cursor: pointer;" />
                                    <i v-if="switchStates.switchStatesIsLargerThan60Days" class="fa-solid fa-check fa-lg yellow-check-icon" style="color: #edf104;  cursor:pointer;" />
                                </div>
                            </el-col>
                            <el-col :span="2" v-if="isRenderedData">
                                <el-switch
                                    v-model="switchStates.switchStatesIsLargerThan60Days"
                                    :disabled="switchStates.switchStatesIsLargerThan60Days"
                                    :class="{ 'switch-on': switchStates.switchStatesIsLargerThan60Days, 'switch-off': !switchStates.switchStatesIsLargerThan60Days }"
                                    active-color="#13ce66"
                                    inactive-color="#919191"
                                    @change="showModalAdd('switchStatesIsLargerThan60Days', $event)"
                                />
                            </el-col>
                        </el-row>

                        <el-row style="margin-bottom:5px;">
                            <el-col :offset="1">
                                <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                    <div
                                        style="font-size:13px; font-weight:550;"
                                        v-if="decisionType == selectedDecisionType('switchStatesIsLargerThan60Days') && switchStates.switchStatesIsLargerThan60Days"
                                    >
                                        Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                    </div>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row style="margin-bottom:5px;">
                            <el-col :span="5" style="font-weight: 500; font-size:13px;">Valuta </el-col>
                            <el-col :span="6" style="font-weight: 500; font-size:13px; margin-bottom: 20px;"> {{ invoiceCustomerDetails.currency }} </el-col>
                        </el-row>

                        <el-row style="margin-bottom:5px; margin-top: 20px;">
                            <el-col :span="6" style="font-weight: 700; font-size:14px;">Fakturarader</el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="7" style="font-weight: 700; font-size:13px;">Beskrivning </el-col>
                            <el-col :span="3" style="font-weight: 700; font-size:13px;text-align: right;">Antal</el-col>
                            <el-col :span="4" style="font-weight: 700; font-size:13px;text-align: right;">A-pris </el-col>
                            <el-col :span="3" style="font-weight: 700; font-size:13px;text-align: right;">Moms </el-col>
                            <el-col :span="3" style="font-weight: 700; font-size:13px;text-align: right;">Rabatt</el-col>
                            <el-col :span="4" style="font-weight: 700; font-size:13px;text-align: right;">Summa </el-col>
                        </el-row>
                        <el-row v-for="(invoiceInfo, index) in invoiceCustomerDetails.invoiceInfo" :key="index" style="margin-bottom:10px;">
                            <el-col :span="7" style="font-weight: 500; font-size:13px;">
                                {{ invoiceInfo.description === null ? "Ingen beskrivning" : invoiceInfo.description }}
                            </el-col>
                            <el-col :span="3" style="font-weight: 500; font-size:13px;text-align: right;">{{ invoiceInfo.quantity }} {{ invoiceInfo.unit }}</el-col>
                            <el-col :span="4" style="font-weight: 500; font-size:13px;text-align: right;">
                                {{ invoiceInfo.unitPrice | swedishNumberFormat }} {{ invoiceCustomerDetails.currency }}</el-col
                            >
                            <el-col :span="3" style="font-weight: 500; font-size:13px;text-align: right;">{{ invoiceInfo.vatPercent }}%</el-col>
                            <el-col :span="3" style="font-weight: 500; font-size:13px;text-align: right;">{{ invoiceInfo.discount }}</el-col>
                            <el-col :span="4" style="font-weight: 500; font-size:13px;text-align: right;">
                                {{ invoiceInfo.netAmount | swedishNumberFormat }} {{ invoiceCustomerDetails.currency }}</el-col
                            >
                        </el-row>
                        <el-col :span="16">&nbsp;</el-col>
                        <el-col :span="4">
                            <el-row style="font-weight: 700; font-size:13px; margin-top:10px;">Netto </el-row>
                            <el-row style="font-weight: 700; font-size:13px;">Moms </el-row>
                            <el-row style="font-weight: 700; font-size:13px;">Avrundning </el-row>
                            <el-row style="font-weight: 700; font-size:13px; margin-bottom:20px;">Att betala </el-row>
                        </el-col>
                        <el-col :span="4" style=" margin-top:10px;">
                            <el-row style="font-weight: 500; font-size:13px;text-align: right;">
                                {{ invoiceCustomerDetails.invoiceInfo.reduce((total, info) => total + info.netAmount, 0) | swedishNumberFormat }} {{ invoiceCustomerDetails.currency }}
                            </el-row>
                            <el-row style="font-weight: 500; font-size:13px;text-align: right;">
                                {{ invoiceCustomerDetails.invoiceInfo.reduce((total, info) => total + info.vatAmount, 0) | swedishNumberFormat }} {{ invoiceCustomerDetails.currency }}
                            </el-row>
                            <el-row style="font-weight: 500; font-size:13px; text-align:right;">
                                {{ invoiceCustomerDetails.rounding | swedishNumberFormat }} {{ invoiceCustomerDetails.currency }}
                            </el-row>
                            <el-row style="font-weight: 500; font-size:13px;text-align: right; margin-bottom:20px;">
                                {{ invoiceCustomerDetails.grossAmountTotal | swedishNumberFormat }} {{ invoiceCustomerDetails.currency }}
                            </el-row>
                        </el-col>
                    </el-card>
                    <el-row style="display:flex;">
                        <el-button
                            v-model="switchStates.switchStatesInvoicesApproved"
                            @click="showModalAdd('switchStatesInvoicesApproved', $event)"
                            style="margin-bottom:20px;"
                            :disabled="switchStates.switchStatesInvoicesApproved"
                            type="primary"
                        >
                            Fakturaraderna godkända
                        </el-button>
                        <i v-if="switchStates.switchStatesInvoicesApproved" class="fa-solid fa-check fa-lg" style="color: #14bf08; margin-top:12px; margin-left:10px;" />
                    </el-row>

                    <el-row />

                    <el-row v-if="switchStates.switchStatesInvoicesApproved">
                        <el-col :span="12">
                            <el-row style="font-weight: 500; font-size:13px;"> {{ invoiceCustomerDetails.signee }} </el-row>
                            <el-row style="font-weight: 500; font-size:13px;"> {{ this.userName }} </el-row>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="12">
                            <div v-for="(comments, decisionType) in commentsByDecisionType" :key="decisionType">
                                <div style="font-size:13px; font-weight:550;" v-if="decisionType == selectedDecisionType('switchStatesInvoicesApproved') && switchStates.switchStatesInvoicesApproved">
                                    Kommentar av {{ comments[0].userName }}: {{ comments[0].comment }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                    <el-card class="box-card" style="margin-top: 10px; margin-right: 20px; margin-bottom: 30px; width: 90%;">
                        <div slot="header" style="display: flex; justify-content: space-between; padding:0px;">
                            <span style="margin-top:5px;">Manuell validering av faktura</span>
                        </div>
                        <div style="width: 100%;">
                            <el-row style="margin-bottom: 10px;">
                                <div class="comment-field">
                                    <div class="comment-header">Kommentarsfält</div>
                                    <textarea
                                        class="comment-textarea"
                                        :placeholder="invoiceCustomerDetails.comment ? invoiceCustomerDetails.comment : 'Skriv din kommentar...'"
                                        v-model="formData.comment"
                                    />
                                </div>
                            </el-row>
                            <el-row>
                                <el-upload ref="upload" action="#" :show-file-list="false" :auto-upload="false" multiple :on-change="filesSelected">
                                    <el-button type="primary" style="margin-bottom:15px;">Bifoga underlag</el-button>
                                    <!-- <div class="h-full flex flex-col justify-center items-center">
                                        <i class="fad fa-cloud-arrow-up text-3xl" />
                                        <p class="text-xs">Dra filer hit eller klicka för att bifoga bilaga</p>
                                    </div> -->
                                </el-upload>
                                <div>
                                    <div class="flex justify-between" v-for="document in this.filesData" :key="document.uid">
                                        <p @click="changeFilePreview(document)" class="p-1 mb-2 mr-1 flex-1 text-xs rounded-lg cursor-pointer hover:bg-blue-100">
                                            <i v-if="document.raw.type === 'image/jpeg' || document.raw.type === 'image/png'" class="fa-thin fa-file-image text-2xl mr-1" />
                                            <i v-if="document.raw.type === 'application/pdf'" class="fa-thin fa-file-pdf text-2xl mr-1" />
                                            {{ document.name }}
                                        </p>
                                        <span @click="deleteFile(document.uid)" class="flex justify-center items-center mt-2 w-4 h-4 bg-red-400 text-white text-xs rounded-md cursor-pointer">
                                            <i class="fa-solid fa-x" />
                                        </span>
                                    </div>

                                    <img v-if="selectedDocument" :src="url(selectedDocument.raw)" alt="" />
                                    <object
                                        v-if="selectedDocument && selectedDocument.raw.type === 'application/pdf'"
                                        :data="url(selectedDocument.raw)"
                                        type="application/pdf"
                                        width="120%"
                                        height="600"
                                    />
                                </div>

                                <div>
                                    <div class="flex justify-between" v-for="(document, index) in invoiceCustomerDetails.files" :key="document.uid">
                                        <p @click="changePreviewOfSaved(index)" class="p-1 mb-2 mr-1 flex-1 text-xs rounded-lg cursor-pointer hover:bg-blue-100">
                                            <i v-if="isImage(document.fileName)" class="fa-thin fa-file-image text-2xl mr-1" />
                                            <i v-else-if="isPDF(document.fileName)" class="fa-thin fa-file-pdf text-2xl mr-1" />
                                            {{ document.fileName }}
                                        </p>
                                        <span @click="deleteFile(document.uid)" class="flex justify-center items-center mt-2 w-4 h-4 bg-red-400 text-white text-xs rounded-md cursor-pointer">
                                            <i class="fa-solid fa-x" />
                                        </span>
                                    </div>

                                    <div v-if="invoiceCustomerDetails.files && invoiceCustomerDetails.files.length">
                                        <img
                                            v-if="selectedFileIndex !== null && isImage(invoiceCustomerDetails.files[selectedFileIndex].fileName)"
                                            :src="urlRendered(invoiceCustomerDetails.files[selectedFileIndex])"
                                            alt=""
                                        />
                                        <object
                                            v-if="selectedFileIndex !== null && isPDF(invoiceCustomerDetails.files[selectedFileIndex].fileName)"
                                            :data="urlRendered(invoiceCustomerDetails.files[selectedFileIndex])"
                                            type="application/pdf"
                                            width="120%"
                                            height="600"
                                        />
                                    </div>
                                </div>
                            </el-row>

                            <el-row style="margin-top:20px;">
                                <el-col :span="1" style="font-size: 13px; font-weight: 700; margin-top:7px;">Provision</el-col>
                                <el-col :span="3" :offset="2">
                                    <el-input v-model="commissionPercentage" :placeholder="computedCommissionPlaceholder" class="dark-input" style="text-align: right;" />
                                </el-col>
                                <el-col :span="3">
                                    <el-input v-model="calculatedCommission" readonly class="dark-input" style="text-align: right;" />
                                </el-col>
                            </el-row>

                            <el-row v-if="invoiceCustomerDetails.approvedInvoice === false">
                                <el-col :span="22" :offset="2" style="text-align: right; margin-top:20px;">
                                    <el-button type="primary" :loading="loadingSave" @click="saveInputChanges">Spara alla ändringar</el-button>
                                </el-col>
                            </el-row>
                            <el-row style="margin-top: 20px; margin-bottom:5px;" v-if="invoiceCustomerDetails.approvedInvoice === false">
                                <div style="display:flex; justify-content:space-between;">
                                    <el-button @click="denyFactoringInvoiceDecision" type="primary" :loading="loadingDeny">Neka faktura</el-button>
                                    <el-button
                                        @click="approveFactoringInvoiceDecision"
                                        :disabled="
                                            invoiceCustomerDetails.approvedInvoice === true ||
                                                !switchStates.switchStatesInvoicesApproved ||
                                                hasChanged ||
                                                hasBannerIcon ||
                                                commissionChanged ||
                                                commentChanged ||
                                                !creditCheckComplete ||
                                                invoiceCustomerDetails.isCreditLimitExceeded
                                        "
                                        type="primary"
                                        :loading="loadingApprove"
                                        >Godkänn faktura</el-button
                                    >
                                </div>
                                <!-- <p v-if="hasChanged || commissionChanged || commentChanged" style="font-size: 13px; margin-top:20px;">
                                    Ändringar har gjorts - var vänlig spara innan du godkänner fakturabeslutet.
                                </p>
                                <p v-if="!creditCheckComplete" style="font-size: 13px; margin-top:5px;">
                                    Gör en ny kreditkontroll på kunden innan du godkänner fakturan!
                                </p>
                                <p v-if="hasBannerIcon" style="font-size: 13px; margin-top:5px;">
                                    Godkänn särskilda villkor med kommentar innan godkännande.
                                </p>
                                <p v-if="!switchStates.switchStatesInvoicesApproved" style="font-size: 13px; margin-top:5px;">
                                    Du måste godkänna fakturaraderna innan fakturan kan bli godkänd.
                                </p> -->
                            </el-row>
                            <el-row v-else>
                                <p style="margin-top:30px; font-size: 13px; text-align:right;">
                                    Fakturabeslutet godkänt av {{ invoiceCustomerDetails.invoiceApprovedBy }} {{ invoiceCustomerDetails.invoiceApprovedDate }}
                                </p>
                                <p style="margin-top:10px; font-size: 13px;">Fakturan skickad via {{ invoiceCustomerDetails.deliveredByLetterOrEmail }}</p>
                            </el-row>
                            <el-row>
                                <p v-if="hasChanged || commissionChanged || commentChanged" style="font-size: 13px; margin-top:20px; text-align:right;">
                                    Ändringar har gjorts - var vänlig spara innan du godkänner fakturabeslutet.
                                </p>
                                <p v-if="!creditCheckComplete" style="font-size: 13px; margin-top:5px; text-align:right;">
                                    Gör en ny kreditkontroll på kunden innan du godkänner fakturan!
                                </p>
                                <p v-if="hasBannerIcon" style="font-size: 13px; margin-top:5px; text-align:right;">
                                    Godkänn särskilda villkor med kommentar innan godkännande.
                                </p>
                                <p v-if="!switchStates.switchStatesInvoicesApproved" style="font-size: 13px; margin-top:5px; text-align:right;">
                                    Du måste godkänna fakturaraderna innan fakturan kan bli godkänd.
                                </p>
                            </el-row>
                        </div>
                    </el-card>

                    <ModalAdd
                        @update-displayed-comment="handleUpdateDisplayedComment"
                        :visible="this.modalVisible"
                        :factoring-invoice-decision-id="factoringInvoiceDecisionId"
                        :decision-type-identifier="selectedDecisionType(currentSwitchKey)"
                        @close="onModalAddClose"
                        @lagg-till-click="hideModalSwitchOn"
                        :draft-invoice-id="selectedInvoiceId"
                    />
                </el-col>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "./factoring.api";
import { Switch } from "element-ui";
import queryString from "query-string";
import moment from "moment";
import { Notification } from "element-ui";

const DecisionType = {
    IsCustomerBolagerietClient: 0,
    IsCreditRatingAtLeastThree: 1,
    IsCreditLimitZero: 2,
    HasActiveACaseAtKfm: 3,
    HasAuditNote: 4,
    IsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance: 5,
    HasReqMoreThan8TimesPast3Months: 6,
    HasFSkatt: 7,
    HasVat: 8,
    IsAmountMaximum25PercentOfYearlyRevenue: 9,
    IsCustomerInOurSystem: 10,
    HasLessThan3Over5Days: 11,
    IsAveragePayTimeMax15Days: 12,
    IsLargerThan60Days: 13,
    InvoicesApproved: 14,
    // ActiveOpenBanking: 15,
    // FVat: 16,
    // DebtTaxAccount: 17,
    // CreditCheck: 18,
    // PositiveResult6Months: 19,
    // KassaLikviditet: 20,
    // LessThan3Invoices: 21,
    HasRedFlags: 15,
};

export default {
    data() {
        return {
            newInvoiceDate: undefined,
            totalNetAmount: 0,
            totalVatAmount: 0,
            totalRounding: 0,
            totalPayAmount: 0,
            hasBannerIcon: false,
            isActive: false,
            hasChanged: false,
            initialCommissionPercentage: "",
            commissionPercentage: "",
            calculatedCommissionPercentage: "",
            fileList: [],
            isRenderedData: false,
            loadingSave: false,
            loadingDeny: false,
            loadingApprove: false,
            modalVisible: false,
            currentSwitchKey: "",
            editingInputField: false,
            activeComponent: "ClientFactoringInvoices",
            invoiceCustomerDetails: {},
            commentsByDecisionType: {},
            header: "",
            isSwitchOn: true,
            decisions: [],
            selectedFactoringDecisionId: null,
            invoices: {
                awaitingApprovalInvoices: [],
                otherStatusInvoices: [],
            },
            selectedInvoiceId: null,
            factoringInvoiceDecisionId: 0,

            switchStates: {
                switchStatesIsCustomerBolagerietClient: false,
                switchStatesIsCreditRatingAtLeastThree: false,
                switchStatesIsCreditLimitZero: false,
                switchStatesHasActiveACaseAtKfm: false,
                switchStatesHasAuditNote: false,
                switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance: false,
                switchStatesHasReqMoreThan8TimesPast3Months: false,
                switchStatesHasFSkatt: false,
                switchStatesHasVat: false,
                switchStatesIsAmountMaximum25PercentOfYearlyRevenue: false,
                switchStatesIsCustomerInOurSystem: false,
                switchStatesHasLessThan3Over5Days: false,
                switchStatesIsAveragePayTimeMax15Days: false,
                switchStatesIsLargerThan60Days: false,
                switchStatesInvoicesApproved: false,
                switchStatesHasRedFlags: false,

                //CLIENT CHECKS
                switchStatesOpenBanking: false,
                switchStatesFSkatt: false,
                switchStatesSkuldfri: false,
                switchStatesKreditkontroll: false,
                switchStatesPositivtRes: false,
                switchStatesKassaLik: false,
                switchStatesLessThan3: false,
            },
            fields: [
                { name: "customerName", editedValue: null, editing: false },
                { name: "organizationNumber", editedValue: null, editing: false },
                { name: "box", editedValue: null, editing: false },
                { name: "zipCode", editedValue: null, editing: false },
                { name: "city", editedValue: null, editing: false },
                { name: "email", editedValue: null, editing: false },
                { name: "emailCC", editedValue: null, editing: false },
                { name: "contactPerson", editedValue: null, editing: false },
                { name: "phoneNumber", editedValue: null, editing: false },
            ],
            originalDetails: {},
            retrievedCustomerInfo: {
                retrievedCustomerName: "a",
            },
            formData: {
                comment: "",
            },
            userName: "",
            filesData: [],
            selectedDocument: null,
            selectedFileIndex: null,
            invoiceClick: false,
            invoiceClickSubscribed: false,
            loadingFetch: false,
            commissionChanged: false,
            removeComments: false,
            commentChanged: false,
            creditCheckComplete: false,
            loadingUpdateInvoiceDate: false,
        };
    },

    components: {
        "el-switch": Switch,
        ModalAdd: () => import("./components/FactoringModalAdd.vue"),
        //AproveSwitch: () => import("./components/AproveSwitch.vue"),
    },

    watch: {
        $route: function() {
            this.activeComponent = this.$route.meta.activeTab;
            this.getClientHeader();
        },
        commissionPercentage(newValue, oldValue) {
            if (newValue !== oldValue && newValue !== "") {
                this.commissionChanged = true;
            } else {
                this.commissionChanged = false;
            }
        },
        "formData.comment"(newValue, oldValue) {
            if (newValue !== oldValue && newValue !== "") {
                this.commentChanged = true;
            } else {
                this.commentChanged = false;
            }
        },
    },

    async created() {
        this.getClientHeader();
        this.selectedInvoiceId = this.$route.params.invoiceId;

        if (this.selectedInvoiceId && this.$route.path.includes("getInvoiceInfo")) {
            this.$waiting.start("loading");
            await this.handleInvoiceClick(this.selectedInvoiceId);
        }
        if (this.selectedInvoiceId && this.$route.path.includes("getDraftInvoiceInfo")) {
            this.$waiting.start("loading");
            await this.handleDraftInvoiceClick(this.selectedInvoiceId);
        }
        this.checkBanners();

        this.creditCheckComplete =
            this.invoiceCustomerDetails.hasBeenMoreThan5Days === false &&
            this.invoiceCustomerDetails.creditReportFetchingTime !== null &&
            this.invoiceCustomerDetails.creditReportFetchingTime !== undefined;

        const { dateFrom, dateTo } = queryString.parse(window.location.search, { sort: false });
        if (dateFrom != null && dateTo != null) {
            this.dateRange = [dateFrom, dateTo];
        }
    },

    mounted() {
        this.initialCommissionPercentage = this.commissionPercentage;

        //this.calculatedCommission = this.initialCommissionPercentage;
    },

    computed: {
        calculatedCommission() {
            if (!this.commissionPercentage || !this.invoiceCustomerDetails.paymentTermsInDays) {
                return this.initialCommissionPercentage;
            }

            let commissionPercentage = this.commissionPercentage.toString().replace(".", ",");

            commissionPercentage = parseFloat(commissionPercentage.replace(",", "."));

            let calculatedCommission = this.invoiceCustomerDetails.paymentTermsInDays < 30 ? commissionPercentage : (commissionPercentage / 30) * this.invoiceCustomerDetails.paymentTermsInDays;
            return calculatedCommission.toFixed(2);
        },

        computedCommissionPlaceholder() {
            return this.invoiceCustomerDetails.commission || "ex. 3.5";
        },

        isInvoiceRoute() {
            return this.$route.path.includes("/invoices");
        },
    },

    methods: {
        // datePickerChange(date) {
        //     if (date) {
        //         this.invoiceCustomerDetails.invoiceDate = this.invoiceDateResponse.newInvoiceDate;
        //     }
        // },
        formatDate(dateString) {
            const date = new Date(dateString);
            const formattedDate = date.toLocaleDateString("sv-SE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });

            return formattedDate;
        },
        checkBanners() {
            const bannerIcons = document.querySelectorAll(".fa-ban");

            bannerIcons.forEach(bannerIcon => {
                const iconColor = window.getComputedStyle(bannerIcon).color;

                if (iconColor === "rgb(227, 22, 22)") {
                    this.hasBannerIcon = true;
                } else {
                    this.hasBannerIcon = false;
                }
            });
            if (this.hasBannerIcon.length === 0) {
                this.hasBannerIcon = false;
            }
        },
        async handleInvoiceClick(selectedInvoiceId) {
            this.$waiting.start("loading");
            try {
                this.invoiceCustomerDetails = await Api.getInvoiceInfo(this.$route.params.clientId, selectedInvoiceId);
                this.newInvoiceDate = this.invoiceCustomerDetails.invoiceDate;
                this.originalDetails = { ...this.invoiceCustomerDetails };

                this.fields.forEach(field => {
                    field.value = this.invoiceCustomerDetails[field.name];
                });

                this.isRenderedData = true;
                this.factoringInvoiceDecisionId = this.invoiceCustomerDetails.factoringInvoiceDecisionId;

                if (this.invoiceCustomerDetails.factoringInvoiceDecisionComments && this.invoiceCustomerDetails.factoringInvoiceDecisionComments.length > 0) {
                    this.invoiceCustomerDetails.factoringInvoiceDecisionComments.forEach(comment => {
                        this.handleUpdateDisplayedComment(comment);
                    });
                }

                if (this.invoiceCustomerDetails.approvedInvoice === true || this.invoiceCustomerDetails.isDenied === true) {
                    this.isActive = false;
                } else {
                    this.isActive = true;
                }
            } catch (error) {
                console.error("Error loading data:", error);
            } finally {
                this.$waiting.end("loading");
            }
        },
        async handleDraftInvoiceClick(selectedInvoiceId) {
            this.$waiting.start("loading");
            try {
                this.invoiceCustomerDetails = await Api.getDraftInvoiceInfo(this.$route.params.clientId, selectedInvoiceId);
                this.newInvoiceDate = this.invoiceCustomerDetails.invoiceDate;
                this.originalDetails = { ...this.invoiceCustomerDetails };

                this.fields.forEach(field => {
                    field.value = this.invoiceCustomerDetails[field.name];
                });

                this.isRenderedData = true;
                this.factoringInvoiceDecisionId = this.invoiceCustomerDetails.factoringInvoiceDecisionId;

                if (this.invoiceCustomerDetails.factoringInvoiceDecisionComments && this.invoiceCustomerDetails.factoringInvoiceDecisionComments.length > 0) {
                    this.invoiceCustomerDetails.factoringInvoiceDecisionComments.forEach(comment => {
                        this.handleUpdateDisplayedComment(comment);
                    });
                }

                if (this.invoiceCustomerDetails.approvedInvoice === true) {
                    this.isActive = false;
                } else {
                    this.isActive = true;
                }
            } catch (error) {
                console.error("Error loading data:", error);
            } finally {
                this.$waiting.end("loading");
            }
        },
        getRowColor(index) {
            const colors = ["bg-red-200", "bg-white", "bg-yellow-200"];
            return colors[index % colors.length];
        },
        getFileTypeFromFileName(fileName) {
            const extension = fileName
                .split(".")
                .pop()
                .toLowerCase();
            switch (extension) {
                case "pdf":
                    return "application/pdf";
                case "jpg":
                case "jpeg":
                    return "image/jpeg";
                case "png":
                    return "image/png";
                default:
                    return "";
            }
        },

        isImage(fileName) {
            const mimeType = this.getFileTypeFromFileName(fileName);
            return mimeType === "image/jpeg" || mimeType === "image/png";
        },

        isPDF(fileName) {
            const mimeType = this.getFileTypeFromFileName(fileName);
            return mimeType === "application/pdf";
        },

        url(file) {
            return URL.createObjectURL(file);
        },

        urlRendered(file) {
            const byteCharacters = atob(file.base64Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const fileType = this.getFileTypeFromFileName(file.fileName);
            const blob = new Blob([byteArray], { type: fileType });

            return URL.createObjectURL(blob);
        },

        changeFilePreview(document) {
            this.selectedDocument = document;
        },

        changePreviewOfSaved(index) {
            this.selectedFileIndex = index;
        },

        filesSelected(file) {
            this.filesData.push(file);
            this.changeFilePreview(file);
            this.$emit("filesSelected", this.filesData);
        },

        deleteFile(uid) {
            if (this.selectedDocument.uid === this.filesData.filter(item => item.uid === uid)[0].uid) {
                this.selectedDocument = null;
            }

            this.filesData = this.filesData.filter(item => item.uid !== uid);

            if (this.filesData.length) {
                this.changeFilePreview(this.filesData[0]);
            }

            this.$emit("filesSelected", this.filesData);
        },

        reset() {
            this.filesData = [];
            this.selectedDocument = null;
        },

        getAdditionalValueForInvoiceDetails(index) {
            if (index === 0) {
                return this.invoiceCustomerDetails.retrievedCustomerName;
            } else if (index === 1) {
                const registrationNumber = this.invoiceCustomerDetails.retrievedRegistrationNumber;
                return this.formatRegistrationNumber(registrationNumber);
            } else if (index === 2) {
                return this.invoiceCustomerDetails.retrievedAddress;
            } else if (index === 3) {
                return this.invoiceCustomerDetails.retrievedZipCode;
            } else if (index === 4) {
                return this.invoiceCustomerDetails.retrievedCity;
            }
        },

        formatRegistrationNumber(registrationNumber) {
            let formattedNumber;
            if (registrationNumber && registrationNumber.length >= 6) {
                formattedNumber = registrationNumber.substring(0, 6) + "-" + registrationNumber.substring(6);
            } else {
                formattedNumber = registrationNumber;
            }

            return formattedNumber;
        },

        toggleEditField(field) {
            field.editing = !field.editing;
            field.editedValue = field.value;
        },

        saveChanges(field) {
            if (field.editedValue !== "") {
                field.value = field.editedValue;
                this.hasChanged = true;
            }
            field.editing = false;
        },

        selectedDecisionType(decisionTypeIdentifier) {
            if (decisionTypeIdentifier === "switchStatesIsCustomerBolagerietClient") {
                return DecisionType.IsCustomerBolagerietClient;
            } else if (decisionTypeIdentifier === "switchStatesIsCreditRatingAtLeastThree") {
                return DecisionType.IsCreditRatingAtLeastThree;
            } else if (decisionTypeIdentifier === "switchStatesIsCreditLimitZero") {
                return DecisionType.IsCreditLimitZero;
            } else if (decisionTypeIdentifier === "switchStatesHasActiveACaseAtKfm") {
                return DecisionType.HasActiveACaseAtKfm;
            } else if (decisionTypeIdentifier === "switchStatesHasAuditNote") {
                return DecisionType.HasAuditNote;
            } else if (decisionTypeIdentifier === "switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance") {
                return DecisionType.IsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance;
            } else if (decisionTypeIdentifier === "switchStatesHasReqMoreThan8TimesPast3Months") {
                return DecisionType.HasReqMoreThan8TimesPast3Months;
            } else if (decisionTypeIdentifier === "switchStatesHasFSkatt") {
                return DecisionType.HasFSkatt;
            } else if (decisionTypeIdentifier === "switchStatesHasVat") {
                return DecisionType.HasVat;
            } else if (decisionTypeIdentifier === "switchStatesIsAmountMaximum25PercentOfYearlyRevenue") {
                return DecisionType.IsAmountMaximum25PercentOfYearlyRevenue;
            } else if (decisionTypeIdentifier === "switchStatesIsCustomerInOurSystem") {
                return DecisionType.IsCustomerInOurSystem;
            } else if (decisionTypeIdentifier === "switchStatesHasLessThan3Over5Days") {
                return DecisionType.HasLessThan3Over5Days;
            } else if (decisionTypeIdentifier === "switchStatesIsAveragePayTimeMax15Days") {
                return DecisionType.IsAveragePayTimeMax15Days;
            } else if (decisionTypeIdentifier === "switchStatesIsLargerThan60Days") {
                return DecisionType.IsLargerThan60Days;
            } else if (decisionTypeIdentifier === "switchStatesInvoicesApproved") {
                return DecisionType.InvoicesApproved;
            } else if (decisionTypeIdentifier === "switchStatesActiveOpenBanking") {
                return DecisionType.ActiveOpenBanking;
            } else if (decisionTypeIdentifier === "switchStatesFSkatt") {
                return DecisionType.FVat;
            } else if (decisionTypeIdentifier === "switchStatesSkuldfri") {
                return DecisionType.DebtTaxAccount;
            } else if (decisionTypeIdentifier === "switchStatesKreditkontroll") {
                return DecisionType.CreditCheck;
            } else if (decisionTypeIdentifier === "switchStatesPositivtRes") {
                return DecisionType.PositiveResult6Months;
            } else if (decisionTypeIdentifier === "switchStatesKassaLik") {
                return DecisionType.KassaLikviditet;
            } else if (decisionTypeIdentifier === "switchStatesLessThan3") {
                return DecisionType.LessThan3Invoices;
            } else if (decisionTypeIdentifier === "switchStatesHasRedFlags") {
                return DecisionType.HasRedFlags;
            }
        },

        handleUpdateDisplayedComment(updatedComment) {
            const { comment, decisionType, userName } = updatedComment;

            const decisionTypes = Object.keys(this.switchStates)[decisionType];
            this.switchStates[decisionTypes] = true;
            this.commentsByDecisionType[decisionType] = [{ userName, comment }];

            this.removeComments = false;

            // if (this.commentsByDecisionType.hasOwnProperty(decisionType)) {
            //     this.commentsByDecisionType[decisionType].push(comment);
            // } else {
            //     this.commentsByDecisionType[decisionType] = [comment];
            // }
        },

        hideModalSwitchOn() {
            this.checkBanners();
            this.modalVisible = false;
        },
        async updateInvoiceDates() {
            this.loadingUpdateInvoiceDate = true;
            const newInvoiceDate = moment(this.newInvoiceDate).format("YYYY-MM-DD");
            try {
                var response = await Api.saveInvoiceDate({
                    invoiceDate: newInvoiceDate,
                    draftInvoiceId: this.selectedInvoiceId,
                });
                if (response.success == false) {
                    Notification.error({
                        title: "Info",
                        message: response.message,
                        duration: 5000,
                    });
                } else {
                    this.invoiceCustomerDetails.invoiceDate = response.invoiceDate;
                    this.invoiceCustomerDetails.dueDate = moment(response.dueDate).format("YYYY-MM-DD");
                    this.invoiceCustomerDetails.paymentTermsInDays = response.paymentTermsInDays;
                    this.invoiceCustomerDetails.isLargerThan60Days = response.paymentTermsInDays > 60;

                    if (response.isMoreThan4DaysDiff === true) {
                        this.invoiceCustomerDetails.isDifferenceMoreThan4Days = true;
                    } else {
                        this.invoiceCustomerDetails.isDifferenceMoreThan4Days = false;
                    }
                    Notification.success({
                        title: "Ändring",
                        message: "Fakturadatumet uppdaterades!",
                        duration: 5000,
                    });
                }
            } catch (error) {
                console.error("Error loading data:", error);
            }
            this.loadingUpdateInvoiceDate = false;
        },

        async showModalAdd(switchKey, event) {
            if (event) {
                this.modalVisible = true;
            }
            this.currentSwitchKey = switchKey;
            this.switchStates[switchKey] = event;

            // if (switchKey === "switchStatesInvoicesApproved") {

            // }
        },
        onModalAddClose() {
            this.modalVisible = false;
            this.switchStates[this.currentSwitchKey] = false;
        },

        async getClientHeader() {
            this.header = await Api.getClientHeader(this.$route.params.clientId);
        },

        async updateCustomerInfo() {
            // this.$waiting.start("loading");
            this.loadingFetch = true;

            this.totalPayAmount = this.invoiceCustomerDetails.invoiceInfo.reduce((total, info) => total + info.payAmount);

            try {
                this.retrievedCustomerInfo = await Api.updateRetrievedCustomerInfo({
                    draftInvoiceId: this.selectedInvoiceId,
                    grossAmount: this.totalPayAmount.payAmount,
                    factoringInvoiceDecisionId: this.factoringInvoiceDecisionId,
                });
            } catch (error) {
                console.error("Error loading data:", error);
            } finally {
                // this.$waiting.end("loading");
                this.invoiceCustomerDetails.retrievedCustomerName = this.retrievedCustomerInfo.data.retrievedCustomerName;
                this.invoiceCustomerDetails.retrievedRegistrationNumber = this.retrievedCustomerInfo.data.retrievedRegistrationNumber;
                // this.invoiceCustomerDetails.retrievedRegistrationNumber = this.formatRegistrationNumber(this.retrievedCustomerInfo.data.retrievedRegistrationNumber);
                this.invoiceCustomerDetails.retrievedAddress = this.retrievedCustomerInfo.data.retrievedAddress;
                this.invoiceCustomerDetails.retrievedZipCode = this.retrievedCustomerInfo.data.retrievedZipCode;
                this.invoiceCustomerDetails.retrievedCity = this.retrievedCustomerInfo.data.retrievedCity;
                this.invoiceCustomerDetails.isCreditRatingAtLeastThree = this.retrievedCustomerInfo.data.isCreditRatingAtLeastThree;
                this.invoiceCustomerDetails.isCreditLimitZero = this.retrievedCustomerInfo.data.isCreditLimitZero;
                this.invoiceCustomerDetails.hasActiveACaseAtKfm = this.retrievedCustomerInfo.data.hasActiveACaseAtKfm;
                this.invoiceCustomerDetails.hasAuditNote = this.retrievedCustomerInfo.data.hasAuditNote;
                this.invoiceCustomerDetails.isInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance = this.retrievedCustomerInfo.data.isInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance;
                this.invoiceCustomerDetails.hasReqMoreThan8TimesPast3Months = this.retrievedCustomerInfo.data.hasReqMoreThan8TimesPast3Months;
                this.invoiceCustomerDetails.hasFSkatt = this.retrievedCustomerInfo.data.hasFSkatt;
                this.invoiceCustomerDetails.hasVat = this.retrievedCustomerInfo.data.hasVat;
                this.invoiceCustomerDetails.isAmountMaximum25PercentOfYearlyRevenue = this.retrievedCustomerInfo.data.isAmountMaximum25PercentOfYearlyRevenue;
                this.invoiceCustomerDetails.showCreditRating = this.retrievedCustomerInfo.data.showCreditRating;
                this.invoiceCustomerDetails.showCreditLimit = this.retrievedCustomerInfo.data.showCreditLimit;
                this.invoiceCustomerDetails.showNumberOfAccountantComments = this.retrievedCustomerInfo.data.showNumberOfAccountantComments;
                this.invoiceCustomerDetails.showKfmDebtBalance = this.retrievedCustomerInfo.data.showKfmDebtBalance;
                this.invoiceCustomerDetails.show50PercentOfDebtBalance = this.retrievedCustomerInfo.data.show50PercentOfDebtBalance;
                this.invoiceCustomerDetails.showAmountOfRequests = this.retrievedCustomerInfo.data.showAmountOfRequests;
                this.invoiceCustomerDetails.showAnnualRevenue = this.retrievedCustomerInfo.data.showAnnualRevenue;
                this.invoiceCustomerDetails.show25PercentOfAnnualRevenue = this.retrievedCustomerInfo.data.show25PercentOfAnnualRevenue;
                this.invoiceCustomerDetails.publicDebtsJson = this.retrievedCustomerInfo.data.publicDebtsJson;
                this.invoiceCustomerDetails.enforcementAgencyDebtsJson = this.retrievedCustomerInfo.data.enforcementAgencyDebtsJson;
                this.invoiceCustomerDetails.enforcementAgencyHistoryDebtsJson = this.retrievedCustomerInfo.data.enforcementAgencyHistoryDebtsJson;
                this.invoiceCustomerDetails.paymentNotesJson = this.retrievedCustomerInfo.data.paymentNotesJson;
                this.invoiceCustomerDetails.creditReportFetchingTime = this.retrievedCustomerInfo.data.creditReportFetchingTime;

                this.switchStates.switchStatesIsCreditRatingAtLeastThree = false;
                this.switchStates.switchStatesIsCreditLimitZero = false;
                this.switchStates.switchStatesHasActiveACaseAtKfm = false;
                this.switchStates.switchStatesHasAuditNote = false;
                this.switchStates.switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance = false;
                this.switchStates.switchStatesHasReqMoreThan8TimesPast3Months = false;
                this.switchStates.switchStatesHasFSkatt = false;
                this.switchStates.switchStatesHasVat = false;
                this.switchStates.switchStatesIsAmountMaximum25PercentOfYearlyRevenue = false;
                this.removeComments = true;
                this.invoiceCustomerDetails.hasBeenMoreThan5Days = false;
                this.creditCheckComplete =
                    this.invoiceCustomerDetails.hasBeenMoreThan5Days === false &&
                    this.invoiceCustomerDetails.creditReportFetchingTime !== null &&
                    this.invoiceCustomerDetails.creditReportFetchingTime !== undefined;

                this.$notify.success({ title: "Ny kundinformation inhämtad!" });
                this.loadingFetch = false;
            }
        },

        tabClick(event) {
            this.$router.push({ path: "/clientApproved/" + this.$route.params.clientId + "/" + event.name });
        },
        onSwitchChange(value) {
            this.isSwitchOn = value;
        },
        async saveInputChanges() {
            const updateData = {};
            const modifiedFields = [];
            this.loadingSave = true;

            try {
                await Api.saveFactoringInvoiceDocumentBlobs({ clientId: this.$route.params.clientId, draftInvoiceId: this.selectedInvoiceId, filesData: this.filesData });
            } catch (e) {
                console.error(`Error updating fields:`, e);
                throw e;
            }

            this.fields.forEach(field => {
                if (field.editedValue !== this.originalDetails[field.name]) {
                    updateData[field.name] = field.editedValue;
                    modifiedFields.push(field);
                }
            });

            await this.saveField(updateData, modifiedFields);
        },

        async saveField(updateData, modifiedFields) {
            try {
                const inputFields = await Api.updateInputFields({
                    clientId: this.$route.params.clientId,
                    draftInvoiceId: this.selectedInvoiceId,
                    fields: updateData,
                });

                if (inputFields) {
                    modifiedFields.forEach(field => {
                        this.originalDetails[field.name] = field.value;
                        field.editing = false;
                    });
                }

                if (this.formData.comment && this.formData.comment.trim() !== "") {
                    await Api.saveAdditionalComment({
                        clientId: this.$route.params.clientId,
                        factoringInvoiceDecisionId: this.factoringInvoiceDecisionId,
                        additionalComment: this.formData.comment,
                    });
                }

                await Api.saveCommission({
                    clientId: this.$route.params.clientId,
                    draftInvoiceId: this.selectedInvoiceId,
                    commissionPercentage: parseFloat(this.calculatedCommission.toString().replace(",", ".")),
                    factoringInvoiceDecisionId: this.factoringInvoiceDecisionId,
                });

                this.$notify.success({ title: "Uppdaterat!" });
            } catch (e) {
                console.error(`Error updating fields:`, e);
                throw e;
            } finally {
                this.loadingSave = false;
                this.hasChanged = false;
                this.commissionChanged = false;
                this.commentChanged = false;
            }
        },

        async approveFactoringInvoiceDecision() {
            const bannerIcons = document.querySelectorAll(".fa-ban");

            bannerIcons.forEach(bannerIcon => {
                const iconColor = window.getComputedStyle(bannerIcon).color;

                if (iconColor === "rgb(227, 22, 22)") {
                    this.hasBannerIcon = true;
                }
            });

            if (this.hasBannerIcon) {
                alert("Samtliga särskilda villkor ej godkända. Var god lägg till kommentarer på de villkor som är röda genom att trycka på switchen.");
            }

            if (!this.hasBannerIcon) {
                try {
                    this.loadingApprove = true;
                    await Api.approveInvoice({
                        clientId: this.$route.params.clientId,
                        factoringInvoiceDecisionId: this.factoringInvoiceDecisionId,
                        draftInvoiceId: this.selectedInvoiceId,
                        details: this.invoiceCustomerDetails,
                    });
                    this.isActive = false;
                    this.$notify.success({ title: "Fakturabeslut godkänt!" });
                } catch (error) {
                    console.error("Error approving invoice:", error);
                } finally {
                    this.loadingApprove = false;
                    this.$router.push({ path: "/clientApproved/" + this.$route.params.clientId + "/invoices" });
                }
            }
        },

        async denyFactoringInvoiceDecision() {
            try {
                this.loadingDeny = true;
                await Api.denyInvoice({
                    factoringInvoiceDecisionId: this.invoiceCustomerDetails.factoringInvoiceDecisionId,
                    details: this.invoiceCustomerDetails,
                });
                // this.isActive = false;
            } catch (error) {
                console.error("Error denying invoice:", error);
            } finally {
                this.$notify.success({ title: "Fakturabeslut nekat och fakturan är utskickad som vanligt till kund!" });
                this.loadingDeny = false;
                this.$router.push({ path: "/clientApproved/" + this.$route.params.clientId + "/invoices" });
            }
        },
    },
};
</script>

<style scoped>
.dark-input .el-input__inner::placeholder {
    color: #666;
}
.comment-field {
    position: relative;
    max-width: 60%;
}

.comment-header {
    position: absolute;
    top: -10px;
    background-color: white;
    padding: 0 10px;
    font-size: 13px;
    font-weight: 550;
    left: 20px;
}

.comment-textarea {
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 13px;
    padding: 20px 10px;
    width: 100%;
    height: 90px !important;
    resize: vertical;
}

.comment-textarea::placeholder {
    color: rgba(0, 0, 0, 0.6);
}

.switch-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.headerAndSwitch {
    display: flex;
    justify-content: space-between;
}

.switch-on {
    --el-switch-on-color: #13ce66;
    --el-switch-off-color: #ff4949;
}

.switch-off {
    --el-switch-on-color: #ff4949;
    --el-switch-off-color: #13ce66;
}

.custom-font-style {
    font-size: 13px;
    font-weight: 550;
}

.approval-button {
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 0 5px rgba(0, 255, 0, 0);
}

.approval-button:hover {
    border-color: rgb(3, 70, 3);
}

.inactive {
    pointer-events: none;
}
.tooltip-container {
    position: relative;
    display: inline-block;
}
.tooltip-text {
    visibility: hidden;
    width: auto;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1000;
    top: 20%;
    left: calc(100% + 50px);
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
}
.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
.tooltip-trigger {
    display: inline-block;
    margin-left: 10px;
}
</style>
